<template>
  <b-card :class="{selected, editing, active, disabled}" class="paragraph-item" :data-id="item.id">
    <b-row align-v="center" slot="header">
      <b-col>
        <b v-if="editing">正在编辑段落</b>
        <template v-else>
          <b-badge variant="success" v-if="item.added" fw>New</b-badge>
          <fa :icon="'align-' + textAlign" v-if="textAlign"/>
          <fa icon="arrow-alt-to-left" v-if="textIndent === 'unindent'"/>
          <b>段落{{index + 1}}</b>
          <span v-if="tag === 'h2'">(小标题)</span>
          <span v-else-if="tag === 'video'">(视频)</span>
          <span v-else>(图文)</span>
        </template>
      </b-col>
      <b-col cols="auto">
        <slot name="header" v-if="$slots.header"></slot>
        <template v-else>
          <b-link v-if="editNote" @click="editNote = false">返回</b-link>
          <template v-else-if="canEdit">
            <template v-if="editing">
              <b-link class="mr-2" :disabled="saving || uploading" @click="cancel">取消</b-link>
              <b-link :disabled="!isDirty || saving || uploading" @click="save" variant="success">
                <fa icon="spinner" spin v-if="saving"/>
                <fa icon="check" v-else/>
                保存
              </b-link>
            </template>

            <template v-else>
              <b-link class="mr-2" :disabled="saving" @click="edit">
                <fa icon="pencil"/>
                编辑
              </b-link>
              <b-link :disabled="saving || deleting" @click="deleteItem" class="text-body">
                <fa fw icon="spinner" spin v-if="saving"/>
                <fa fw icon="trash" v-else/>
                删除
              </b-link>
            </template>
          </template>
        </template>
      </b-col>
    </b-row>

    <template v-if="editNote">
      <p>
        <fa icon="note"/>
        您可以在这里为每张图片添加批注，批注将显示在图片下方，每条批注最长32字
      </p>
      <b-row>
        <b-col cols="4" sm="3" v-for="val in pics" :key="val.id">
          <b-form-group>
            <square :src="val.src" border img-size="320"/>
            <b-input class="my-2" v-model="val.note" placeholder="图片批注"/>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template v-else-if="tag === 'p'">
      <template v-if="editing">
        <b-form-group label="对齐方式">
          <select-group v-model="textAlign" :options="alignOptions"/>
        </b-form-group>
        <b-form-group label="缩进">
          <select-group v-model="textIndent" buttons
                        :options="{'': '首行缩进', unindent: '首行顶格（书信格式）'}"/>
        </b-form-group>
        <label>内容</label>
        <textarea class="form-control" rows="3"
                  placeholder="写点什么吧..."
                  v-autofocus v-autosize v-model="text"></textarea>
      </template>

      <template v-else>
        <div class="text" :class="[textAlignClassName]" v-if="text">{{text}}</div>
      </template>

      <upload-zone v-if="editing || pics.length"
                   min-size="0x0"
                   ref="uploadZone"
                   draggable
                   contain
                   :readonly="!editing"
                   v-model="pics"/>

      <div class="mt-3" v-if="editing">
        <b-link class="float-right" v-if="pics.length > 0" @click="editNote = true">
          <fa icon="sticky-note"/>
          为图片添加批注
          <template v-if="picsWithNote.length > 0">({{picsWithNote.length}})</template>
        </b-link>

        <div v-if="pics.length > 1">
          <fa icon="arrows"/>
          拖动图片可调整位置哦
        </div>
      </div>
    </template>

    <template v-else-if="tag === 'h2'">
      <template v-if="editing">
        <select-group class="mb-3" v-model="textAlign" :options="alignOptions"/>
        <textarea class="form-control" placeholder="写个小标题吧..." maxlength="50"
                  v-autofocus v-autosize v-model="h2"></textarea>
      </template>
      <div class="h4 mb-0" :class="['text-' + textAlign]" v-else>{{h2}}</div>
    </template>

    <template v-else-if="tag === 'video'">
      <template v-if="editing">
        <select-group class="mb-3" v-model="textAlign" :options="alignOptions"/>
        <textarea class="form-control" rows="3"
                  placeholder="写点什么吧..."
                  v-autofocus v-autosize v-model="text"></textarea>
      </template>
      <div class="text" :class="[textAlignClassName]" v-else-if="text">{{text}}</div>
      <video-player :poster="video.src" height="100%" :src="video.url" style="max-width: 240px;"/>
    </template>
  </b-card>
</template>

<script>
import { chain, cloneDeep, get, isEqual } from 'lodash'

import UploadZone from './UploadZone'
import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'paragraphItem',
  components: {VideoPlayer, UploadZone},
  props: {
    value: {
      type: Object,
      required: true
    },
    index: Number,
    disabled: Boolean,
    selected: Boolean,
    bid: String,
    aid: String
  },
  data() {
    return {
      original: null,

      editing: false,
      saving: false,
      deleting: false,
      editNote: false,

      tag: 'p',
      h2: '',
      text: '',
      pics: [],
      video: null,

      fontSize: null,
      textAlign: '',
      textIndent: '',

      alignOptions: [
        {value: '', icon: '', title: '默认'},
        {value: 'left', icon: 'align-left', title: '左对齐'},
        {value: 'center', icon: 'align-center', title: '居中对齐'},
        {value: 'right', icon: 'align-right', title: '右对齐'}
      ]
    }
  },
  computed: {
    item() {
      return this.value
    },
    canEdit() {
      return !this.disabled && !!this.item.id
    },
    active() {
      return this.$route.query.msgId === this.item.id
    },
    textAlignClassName() {
      return this.textAlign ? 'text-' + this.textAlign : ''
    },
    uploading() {
      return get(this.$refs.uploadZone, 'uploading')
    },
    picsWithNote() {
      return this.pics.filter(i => i.note)
    },
    style() {
      return chain(this.item.style).split(';').concat(this.textAlign).map(p => {
        if (/center|left|right/.test(p)) {
          p = this.textAlign
        }
        return p
      }).concat(this.textIndent).uniq().filter(Boolean).join(';').value()
    },
    isEmpty() {
      return !this.text && !this.pics?.length && !this.h2
    },
    isDirty() {
      return [
        !isEqual(this.textAlign, this.original?.textAlign),
        !isEqual(this.textIndent, this.original?.textIndent),
        !isEqual(this.text, this.original?.text),
        !isEqual(this.pics, this.original?.pics),
        !isEqual(this.h2, this.original?.h2)
      ].some(Boolean)
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        const els = val.elements
        this.video = els.find(i => i.tag === 'video')
        this.pics = els.filter(i => i.tag === 'img') || []
        this.text = els.find(i => i.tag === 'p')?.text || ''
        this.h2 = els.find(i => i.tag === 'h2')?.text || ''

        const style = els?.[0]?.style || ''
        this.fontSize = parseInt(((style).match(/\d+px/) || [])[0])
        this.textAlign = (style.match(/left|center|right/) || [])[0] || ''
        this.textIndent = (style.match(/unindent/) || [])[0] || ''

        if (this.h2) {
          this.tag = 'h2'
        }
        if (this.video) {
          this.tag = 'video'
        }
      }
    }
  },
  mounted() {
    return setTimeout(() => {
      if (this.active) {
        this.scrollIntoView(this.$el)
        window.scrollBy(0, -72)
      }
    })
  },
  methods: {
    edit() {
      this.original = cloneDeep({
        h2: this.h2,
        text: this.text,
        pics: this.pics,
        video: this.video
      })
      this.editing = true
    },
    cancel() {
      this.text = cloneDeep(this.original.text)
      this.pics = cloneDeep(this.original.pics)
      this.h2 = cloneDeep(this.original.h2)
      this.editing = false
      this.editNote = false
    },
    async save() {
      try {
        if (!this.isDirty) {
          return
        }
        this.saving = true

        if (this.isEmpty) {
          // 编辑后为空时调用删除
          return this.deleteItem()
        }

        const elements = []

        if (this.tag === 'p') {
          if (this.text) {
            elements.push({
              tag: 'p',
              text: this.text,
              style: this.style
            })
          }
          if (this.pics?.length) {
            elements.push(...this.pics.map(el => {
              return {
                tag: 'img',
                w: el.w || el.width,
                h: el.h || el.height,
                src: el.src,
                note: el.note || ''
              }
            }))
          }
        }

        if (this.tag === 'h2') {
          if (this.h2) {
            elements.push({
              tag: 'h2',
              text: this.h2,
              style: this.style
            })
          }
        }

        if (this.tag === 'video') {
          if (this.text) {
            elements.push({
              tag: 'p',
              text: this.text,
              style: this.style
            })
          }

          elements.push(this.video)
        }

        const item = {
          pid: this.item.id,
          elements
        }

        const data = {
          updateParagraph: item
        }

        await this.$req.post(`/blogbook/books/${this.bid}/articles/${this.aid}/`, data)

        this.$alert.success('段落内容保存成功')
        this.editing = false

        this.$emit('input', item)
      } finally {
        this.saving = false
        this.editNote = false
      }
    },
    async deleteItem() {
      this.deleting = true
      const confirmed = await this.$dialog.confirm({
        title: '删除内容',
        content: '是否删除本条内容'
      })
      if (!confirmed) {
        this.deleting = false
        return
      }
      try {
        this.saving = true
        await this.$req.post(`/blogbook/books/${this.bid}/articles/${this.aid}/`, {
          deleteParagraph: {
            pid: this.item.id
          }
        })
        this.$emit('delete', this.item)
      } finally {
        this.deleting = false
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph-item {
  &.disabled {
    user-select: none;
  }

  &.selected {
    border-color: $primary;
  }

  &.active {
    animation: blink .5s 2 forwards;
    border-color: $primary;
  }

  .small-pill {
    display: inline-block;
    cursor: pointer;
  }

  .select-pill {
    color: $primary;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

}

.image-zone, .upload-zone {
  margin-top: 0;
  margin-bottom: 0;
}

.editor {
  margin-bottom: 1rem;
}

.text, .h2 {
  white-space: pre-wrap;
  @include line-clamp(8);
}

.text, textarea {
  word-break: break-all;
  font-family: $font-family-monospace;

  + .upload-zone, + .video-player {
    margin-top: 1rem;
  }
}

.move {
  cursor: pointer;
}
</style>
