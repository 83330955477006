<template>
  <b-modal class="write-paragraph" :ok-disabled="!canSave"
           title="添加段落" id="addParagraph"
           @hide="$emit('close')"
           @cancel="close" @ok="save" cancel-title="取消"
           ok-title="保存">
    <b-form-group label="段落类型">
      <b-select inline :options="[{value: 'p', text: '普通段落'}, {value: 'h2', text: '小标题'}]"
                v-model="type"/>
    </b-form-group>

    <b-form-group label="段落对齐方式">
      <select-group v-model="textAlign" :options="alignOptions"/>
    </b-form-group>

    <b-form-group label="段落缩进">
      <select-group v-model="textIndent" :options="{'': '首行缩进', unindent: '首行顶格（书信格式）'}"/>
    </b-form-group>

    <b-form-group label="小标题内容（最多50字）" v-if="type === 'h2'">
      <b-input size="lg" autofocus maxlength="50" placeholder="写个小标题吧..." v-model="h2"/>
    </b-form-group>

    <template v-else>
      <b-form-group label="段落内容（最多2000字）">
        <textarea class="form-control" maxlength="2000" placeholder="写点什么吧..."
                  rows="5" v-autofocus v-autosize
                  v-model="text"></textarea>
      </b-form-group>

      <b-form-group label="图片">
        <upload-zone @start="uploading = true" @finish="uploading = false"
                     draggable min-size="0x0" v-model="pics"/>
      </b-form-group>

      <div class="no-select">
        <fa icon="image"/>
        最多9张图片，上传后可拖动调整顺序；为保证质量，图片尺寸须大于640×640
      </div>
    </template>
  </b-modal>
</template>

<script>
import UploadZone from '@/components/UploadZone'

export default {
  name: 'writeParagraph',
  components: {UploadZone},
  computed: {
    canSave() {
      if (this.type === 'p' && !this.pics.length && !this.text) {
        return false
      }
      if (this.type === 'h2' && !this.h2) {
        return false
      }
      return !this.uploading && !this.saving
    }
  },
  props: {
    aid: String,
    bid: String,
    pid: String
  },
  data() {
    return {
      saving: false,
      uploading: false,
      type: 'p',
      pics: [],
      text: '',
      h2: '',

      textAlign: '',
      textIndent: '',

      alignOptions: [
        {value: '', icon: '', title: '默认'},
        {value: 'left', icon: 'align-left', title: '左对齐'},
        {value: 'center', icon: 'align-center', title: '居中对齐'},
        {value: 'right', icon: 'align-right', title: '右对齐'}
      ]
    }
  },
  methods: {
    async save(event) {
      if (!this.canSave) {
        event.preventDefault()
        return this.$alert.warn('不能添加空内容哦')
      }

      const elements = []
      const style = [this.textAlign, this.textIndent].filter(Boolean).join(';')

      if (this.type === 'h2' && this.h2) {
        elements.push({
          tag: 'h2',
          text: this.h2,
          style
        })
      }

      if (this.type === 'p' && this.text) {
        elements.push({
          tag: 'p',
          text: this.text,
          style
        })
      }

      if (this.type === 'p' && this.pics.length) {
        elements.push(...this.pics.map(pic => {
          return {
            w: pic.width,
            h: pic.height,
            tag: 'img',
            src: pic.src
          }
        }))
      }

      this.saving = true

      try {
        const pid = this.pid
        const addParagraph = {elements}
        if (pid) {
          addParagraph[/^\+/.test(pid) ? 'after' : 'before'] = pid.replace(/^\+/, '')
        }
        const url = `/blogbook/books/${this.bid}/articles/${this.aid}/`
        const result = await this.$req.post(url, {addParagraph})
        this.$emit('save', result, pid)
        this.text = ''
        this.h2 = ''
        this.pics = []
        this.close()
      } finally {
        this.saving = false
      }
    },
    close() {
      this.text = ''
      this.pics = []
      this.$bvModal.hide('addParagraph')
    }
  }
}
</script>

<style scoped lang="scss">
.write-paragraph {
  textarea {
    min-height: 6em;
    max-height: calc(100vh - #{360px});
  }
}
</style>
