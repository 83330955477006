<template>
  <div class="blogbook-catalog">
    <b-card class="select-bar" v-headroom>
      <b-row align-v="center" v-if="selecting">
        <b-col cols="auto">
          <b style="cursor: pointer; user-select: none;" :class="{'text-primary': allChecked}"
             @click="allChecked = !allChecked">
            <fa icon="check-circle" v-if="allChecked"/>
            <fa icon="circle" far class="text-muted" v-else/>
            全选
          </b>
        </b-col>
        <b-col>
          已选
          <span :class="{'text-primary': selected.length}">{{selected.length}}</span>
          条
        </b-col>
        <b-col cols="auto">
          <b-link :disabled="saving || !selected.length" @click="deleteSelected">
            <fa icon="spinner" spin v-if="saving"/>
            <fa icon="trash" v-else/>
            删除已选
          </b-link>
          &#12288;
          <b-link :disabled="saving" @click="cancelSelect">取消</b-link>
        </b-col>
      </b-row>

      <b-row align-v="center" v-else-if="searching">
        <b-col>
          <b-input placeholder="按文本筛选" v-model.trim="keyword" trim autofocus/>
        </b-col>
        <b-col cols="auto" class="pl-0">
          <b-btn @click="reset">取消</b-btn>
          <b-btn @click="selecting = true" variant="primary" :disabled="!catalogList.length">
            批量删除
          </b-btn>
        </b-col>
      </b-row>

      <b-row align-v="center" v-else>
        <b-col>
          <ske width="6em" v-if="loading"/>
          <b v-else>{{book.title}}</b>
        </b-col>
        <b-col cols="auto" v-if="catalogList && catalogList.length">
          <b-link v-if="sorting" class="mr-2" @click="sortByTime">
            <fa icon="sort-numeric-asc"/>
            按时间
          </b-link>
          <b-link class="mr-2" @click="searching = true">
            <fa icon="search"/>
            搜索
          </b-link>
          <b-link @click="sorting = !sorting; cutting = null" class="mr-2">
            <fa :icon="sorting ? 'check' : 'list'"/>
            {{sorting ? '完成' : '排序'}}
          </b-link>
          <b-link @click="selecting = !selecting" v-if="!sorting">
            <fa icon="list-alt"/>
            批量删除
          </b-link>
        </b-col>
      </b-row>
    </b-card>
    <div class="side-menu float-right">
      <side-menu :items="navs" v-headroom="'1rem'"/>
    </div>
    <b-card>
      <template #header>
        <ske width="8em" v-if="loading"/>
        <b v-else>文章（共 {{catalog.items.length || 0}} 篇）</b>
      </template>
      <template v-if="loading">
        <div class="list">
          <div class="item-box" v-for="i in 7" :key="i">
            <ske width="1em"/>
            <ske width="10em"/>
            <ske width="6em" class="float-right"/>
          </div>
        </div>
      </template>
      <empty v-else-if="!catalogList.length">
        <template v-if="keyword">
          <div>这里没有任何与 <b>{{keyword}}</b> 相关内容哦</div>
          <div>换个词筛选试试吧</div>
        </template>
        <template v-else>
          这里还没有内容哦
          <div class="mt-2">
            <b-btn @click="writeArticleDialog = true">
              <fa icon="plus-circle"/>
              新建文章
            </b-btn>
          </div>
        </template>
      </empty>
      <template v-else>
        <div class="list">
          <b-link class="item-box" :to="articleRoute(item.id)"
                  v-for="(item, i) in catalogList"
                  :key="item.id" :class="{cutting: cutting === item}"
                  @click="toggleSelect(item)">
            <div class="article-item">
              <div class="article-number">
                <div v-if="!selecting">{{startIndex + i + 1}}.</div>
                <div v-else>
                  <fa icon="check-circle"
                      :class="{'text-primary': selected.includes(item)}"
                      v-if="selected.includes(item)"/>
                  <fa icon="circle" far class="text-muted" v-else/>
                </div>
              </div>
              <div class="article-title">{{item.title.trim() || '未命名'}}</div>
              <div class="text-muted text-nowrap">
                <span class="text-body" v-if="sorting && cutting === item">
                  <fa icon="check"/>
                  已剪切
                </span>
                <template v-else-if="sorting && cutting">
                  移到此文
                  <b-link v-if="i === 0" @click.stop="sortArticle(i)" :disabled="saving">
                    <fa icon="caret-up"/>
                    之前
                  </b-link>
                  <b-link @click.stop="sortArticle(i, true)"
                          :disabled="i + 1 === catalogList.indexOf(cutting) || saving">
                    之后
                    <fa icon="caret-down"/>
                  </b-link>
                </template>
                <template v-else-if="sorting">
                  <fa icon="cut"/>
                  点击任意位置剪切
                </template>
                <datetime :value="item.postDate" format="YYYY/MM/DD" v-else/>
              </div>
            </div>
          </b-link>
        </div>
        <div class="text-center" slot="footer">
          <template>
            <b-btn :disabled="catalog.currentPage <= 1" @click="catalog.currentPage = 1">
              «
            </b-btn>
            <b-btn :disabled="catalog.currentPage <= 1" @click="catalog.currentPage--">
              上一页
            </b-btn>
          </template>

          <span class="px-2">{{catalog.currentPage}} / {{catalog.totalPage}}</span>

          <template>
            <b-btn :disabled="catalog.currentPage >= catalog.totalPage"
                   @click="catalog.currentPage++">
              下一页
            </b-btn>
            <b-btn :disabled="catalog.currentPage >= catalog.totalPage"
                   @click="catalog.currentPage = catalog.totalPage">
              »
            </b-btn>
          </template>
        </div>
      </template>
    </b-card>
    <bottom-bar :items="navs" nav/>
    <write-article v-model="writeArticleDialog" @save="addArticle"/>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

export default {
  name: 'blogbookCatalog',
  title: '目录',
  components: {
    SideMenu: require('@/components/SideMenu').default,
    WriteArticle: require('@/components/WriteArticle').default
  },
  mixins: [route('book')],
  data() {
    return {
      fetching: false,
      saving: false,
      selecting: false,
      sorting: false,
      cutting: null,
      selected: [],
      searching: false,
      keyword: '',
      timer: null,

      catalog: {
        items: [],
        currentPage: 1,
        pageSize: 50,
        totalPage: 0
      },

      bid: null,
      writeArticleDialog: false
    }
  },
  beforeRouteLeave(to, from, next) {
    const page = this.catalog.currentPage
    const key = 'catalog.' + this.bid
    this.$ss.set(key, page)
    if (page <= 1) {
      this.$ss.remove(key)
    }
    next()
  },
  async created() {
    const write = this.$route.query.write
    if (write) {
      this.writeArticleDialog = true
    }
    this.bid = this.$route.meta.bookParams.id
    this.loadStatus++
    await this.getCatalog()
    this.loadStatus--
    const page = this.$ss.get('catalog.' + this.bid)
    if (page) {
      this.catalog.currentPage = page
    }
  },
  computed: {
    navs() {
      return [
        {
          title: '新建文章',
          icon: 'plus-circle',
          onClick: () => {
            this.writeArticleDialog = true
          }
        }, {
          title: '回收站',
          icon: 'trash-restore',
          to: `/books/${this.$route.params.bookId}/catalogrestore`,
          disabled: this.saving
        }, {
          title: '预览',
          icon: 'book',
          exact: true,
          hr: 'bottom',
          to: `/books/${this.$route.params.bookId}`,
          variant: 'primary',
          disabled: this.saving
        }
      ]
    },
    allChecked: {
      get() {
        return this.selected.length === this.catalogList.length
      },
      set(val) {
        if (val) {
          this.selected = []
          this.selected.push(...this.catalogList)
        } else {
          this.selected = []
        }
      }
    },
    catalogList() {
      const list = this.catalog.items || []
      if (this.keyword) {
        return list.filter(i => i.title.toLowerCase().includes(this.keyword.toLowerCase()))
      }
      const {currentPage, pageSize} = this.catalog
      const startAt = (currentPage - 1) * pageSize
      return list.slice?.(startAt, startAt + pageSize)
    },
    startIndex() {
      const {currentPage, pageSize} = this.catalog
      return (currentPage - 1) * pageSize
    }
  },
  methods: {
    async sortByTime() {
      const confirmed = await this.$dialog.confirm({
        title: '排序',
        content: '确定按发布时间由远及近一键排序吗'
      })
      if (!confirmed) {
        return
      }
      try {
        await this.$req.post(`/blogbook/books/${this.bid}/`, {
          article_order: {
            order_by: 'post_date'
          }
        })
        this.$alert.success('排序成功')
      } finally {
        this.sorting = false
        this.cutting = null
        this.getCatalog()
      }
    },
    async getCatalog() {
      this.catalog.items = await this.$ajax.fetchBlogbookCatalog({bid: this.bid})
      this.catalog.currentPage = 1
      this.catalog.totalPage = Math.ceil(this.catalog.items.length / this.catalog.pageSize)
    },
    toggleSelect(item) {
      if (this.selecting) {
        if (this.selected.indexOf(item) === -1) {
          this.selected = this.selected.concat(item)
        } else {
          this.selected = this.selected.filter(i => i !== item)
        }
      }
      if (this.sorting) {
        this.cutting = this.cutting === item ? null : item
      }
    },
    cancelSelect() {
      this.selected = []
      this.selecting = false
    },
    async deleteSelected() {
      try {
        this.saving = true
        const aids = this.selected.map(i => i.id).join(',')
        await this.$ajax.deleteBlogbookArticle({bid: this.bid, aids})
        this.$alert.success('操作成功，可在回收箱内恢复已删除内容')
        this.getCatalog(this.bid) // 删除成功后重载
        this.cancelSelect()
      } finally {
        this.saving = false
      }
    },
    articleRoute(aid) {
      if (this.selecting || this.sorting) {
        return
      }
      const bookId = this.$route.params.bookId
      return `/books/${bookId}/editblogbook/${aid}`
    },
    async sortArticle(index, after) {
      try {
        this.saving = true
        const oldItem = this.cutting
        const newItem = this.catalogList[index]

        await this.$req.post(`/blogbook/books/${this.bid}/`, {
          articleOrder: {
            move: oldItem.id,
            [after ? 'after' : 'before']: newItem.id
          }
        })

        const list = this.catalog.items
        const oldIndex = list.indexOf(oldItem)
        let newIndex = list.indexOf(newItem)
        if (newIndex < oldIndex && after) {
          newIndex++
        }
        list.splice(newIndex, 0, list.splice(oldIndex, 1)[0])
        this.$alert.success('排序成功')
      } finally {
        this.saving = false
      }
    },
    addArticle() {
      this.getCatalog(this.bid)
    },
    reset() {
      this.searching = false
      this.keyword = ''
      this.getCatalog(this.bid)
    }
  }
}
</script>

<style lang="scss" scoped>
.blogbook-catalog {
  max-width: 640px;
  position: relative;

  .pointer {
    cursor: pointer;
  }

  .select-bar {
    @include clearfix();
    margin-bottom: 1em;

    &.headroom {
      z-index: 20;
      top: 0;
      border-top: 0;
      width: 100%;
      max-width: 620px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      ~ .card {
        margin-top: 72px;
      }
    }
  }

  .item-box {
    display: block;
    padding: 1rem $card-spacer-x;
    border-bottom: 1px solid $hr-border-color;
    position: relative;

    &.cutting {
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 4px;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $primary;
      }
    }

    .article-item {
      align-items: center;
      display: flex;

      > div:nth-child(3) {
        flex: 1;
      }

      > div:nth-child(1) {
        padding-right: 0.4rem;
      }

      > :nth-child(2) {
        flex: 5;
      }

      > div:nth-child(3) {
        text-align: right;
        flex: 3;
      }

      .article-title {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $body-color;
      }
    }
  }

  .update-container {
    padding: 1.5rem;
    margin-top: 1em;
    text-align: center;
    border-radius: $border-radius;
  }

  .move {
    cursor: pointer;
  }

  .card {
    overflow: hidden;
  }

  .list {
    margin: -$card-spacer-x;
  }

  .article-number {
    min-width: 1.75em;
    color: $text-muted;
  }
}
</style>
