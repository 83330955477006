<template>
  <div class="blogbook-catalog-restore">
    <loading v-if="!loaded" style="height: 39px;"/>
    <div v-else>
      <b-card class="select-bar" v-headroom>
        <b-row align-v="center" v-if="selecting">
          <b-col cols="auto">
            <span style="cursor: pointer; user-select: none;" :class="{'text-primary': allChecked}"
                  @click="allChecked = !allChecked">
              <fa icon="check-circle" v-if="allChecked"/>
              <fa icon="circle" far class="text-muted" v-else/>
              全选
            </span>
          </b-col>
          <b-col>
            已选
            <span :class="{'text-primary': selected.length}">{{selected.length}}</span>
            条
          </b-col>
          <b-col cols="auto">
            <b-link :disabled="saving || !selected.length" @click="deleteSelected">
              <fa icon="spinner" spin v-if="saving"/>
              <fa icon="trash" v-else/>
              恢复已选
            </b-link>
            &#12288;
            <b-link :disabled="saving" @click="cancelSelect">取消</b-link>
          </b-col>
        </b-row>

        <b-row align-v="center" v-else-if="searching">
          <b-col>
            <b-input placeholder="按文本筛选" v-model.trim="keyword" trim autofocus/>
          </b-col>
          <b-col cols="auto" class="pl-0">
            <b-btn @click="reset">取消</b-btn>
            <b-btn @click="selecting = true" variant="primary" :disabled="!catalogList.length">
              批量恢复
            </b-btn>
          </b-col>
        </b-row>

        <b-row align-v="center" v-else>
          <b-col>
            <b>正在恢复</b>
          </b-col>
          <b-col cols="auto" v-if="catalogList && catalogList.length">
            <b-link class="mr-2" @click="searching = true">
              <fa icon="search"/>
              搜索
            </b-link>
            <b-link @click="selecting = !selecting">
              <fa icon="list-alt"/>
              批量恢复
            </b-link>
          </b-col>
        </b-row>
      </b-card>

      <template>
        <div class="side-menu float-right">
          <side-menu :items="navs" v-headroom="'1rem'"/>
        </div>

        <div class="card list-box">
          <div class="card-header">文章（{{totalCount || 0}}）</div>
          <div class="card-list" v-if="catalogList.length">
            <div class="item-box"
                 v-for="(article, i) in catalogList"
                 :key="article.id"
                 @click="toggleSelect(article)">
              <div class="article-item">
                <div>
                  <div v-if="!selecting" class="text-muted">{{ i + 1 }}.</div>
                  <div v-else>
                    <fa icon="check-circle"
                        :class="{'text-primary': selected.indexOf(article) > -1}"
                        v-if="selected.indexOf(article) > -1"/>
                    <fa icon="circle" far class="text-muted" v-else/>
                  </div>
                </div>
                <div class="article-title">
                  {{ article.title.trim() || '未命名' }}
                </div>
                <div class="text-muted" v-show="!selecting">
                  <template>
                    <span class="pointer text-primary" @click="restoreArticle(article)">
                      <fa icon="recycle" fw/>
                      恢复
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="card-list" v-else>
            <empty icon="scroll">没有可恢复的文章</empty>
          </div>
        </div>

        <div class="btn-area" v-if="catalogList.length < totalCount">
          <loading v-if="fetching" style="height: 39px;"/>
          <b-btn block @click="loadMore" v-else>共 {{totalCount}} 篇文章，查看更多</b-btn>
        </div>

        <bottom-bar :items="navs" nav/>
      </template>
    </div>

  </div>
</template>

<script>
import { decodeBookParams } from '@/models/book'
import SideMenu from '@/components/SideMenu'

export default {
  name: 'blogbookCatalogRestore',
  components: {SideMenu},
  title: '恢复目录',
  data() {
    return {
      loaded: false,
      fetching: false,
      selecting: false,
      selected: [],
      page: 1,
      bid: null,

      saving: false,
      searching: false,
      keyword: '',
      timer: null,

      catalogList: [],
      totalCount: 0
    }
  },
  created() {
    const bookId = this.$route.params.bookId
    const {id} = decodeBookParams(bookId)
    this.bid = id
    this.getCatalog(id)
  },
  computed: {
    navs() {
      return [
        {
          title: '返回目录',
          icon: 'list-ol',
          to: `/books/${this.$route.params.bookId}/catalog`,
          disabled: this.saving
        }, {
          title: '预览',
          icon: 'book',
          to: `/books/${this.$route.params.bookId}`,
          exact: true,
          variant: 'primary',
          disabled: this.saving
        }
      ]
    },
    allChecked: {
      get() {
        return this.selected.length === this.catalogList.length
      },
      set(val) {
        if (val) {
          this.selected = []
          this.selected.push(...this.catalogList)
        } else {
          this.selected = []
        }
      }
    }
  },
  watch: {
    keyword: function (val, oldVal) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getCatalog(this.bid)
        this.timer = null
      }, 500)
    }
  },
  methods: {
    getCatalog(bid) {
      this.$ajax.fetchBlogbookArticlesRestore({bid, size: 24, keyword: this.keyword}).then(res => {
        this.page = 1
        this.catalogList = res
        this.totalCount = res.totalCount
        this.loaded = true
      })
    },
    // 加载更多
    loadMore() {
      this.fetching = true
      this.$ajax.fetchBlogbookArticlesRestore({bid: this.bid, page: ++this.page, size: 24, keyword: this.keyword}).then(res => {
        this.catalogList = this.catalogList.concat(res)
        this.fetching = false
      })
    },
    toggleSelect(item) {
      if (!this.selecting) {
        return
      }
      if (this.selected.indexOf(item) === -1) {
        this.selected = this.selected.concat(item)
      } else {
        this.selected = this.selected.filter(i => i !== item)
      }
    },
    cancelSelect() {
      this.selected = []
      this.selecting = false
    },
    deleteSelected() {
      this.saving = true
      const aids = this.selected.map(item => {
        return item.id
      }).join(',')
      this.$ajax.restoreBlogbookArticle({bid: this.bid, aids}).then(res => {
        this.$alert.success('内容恢复成功')
        // 恢复成功后重载
        this.getCatalog(this.bid)
        this.saving = false
        this.cancelSelect()
      }).catch(() => {
        this.$alert.error('恢复失败，请重试')
        this.saving = false
        this.cancelSelect()
      })
    },
    async restoreArticle(item) {
      const confirmed = await this.$dialog.confirm({
        title: '恢复内容',
        content: '是否恢复本条内容'
      })
      if (!confirmed) {
        return
      }
      this.selected.push(item)
      this.deleteSelected()
    },
    reset() {
      this.searching = false
      this.keyword = ''
      this.getCatalog(this.bid)
    }
  }
}
</script>

<style lang="scss" scoped>
.blogbook-catalog-restore {
  max-width: 640px;
  position: relative;

  .pointer {
    cursor: pointer;
  }

  .select-bar {
    @include clearfix();
    margin-bottom: 1em;

    &.headroom {
      z-index: 20;
      top: 0;
      border-top: 0;
      width: 100%;
      max-width: 620px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      ~ .list-box {
        margin-top: 72px;
      }
    }
  }

  .card {
    .card-header {
      border-bottom: 0;
    }

    .card-list {
      padding: 0 1.25rem;
    }
  }

  .item-box {
    display: block;
    background-color: #fff;
    padding: 1rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    small {
      color: #b1b1b1;
    }

    .article-item {
      align-items: center;
      display: flex;

      > div:nth-child(3) {
        flex: 1;
      }

      > div:nth-child(1) {
        padding-right: 0.4rem;
      }

      > :nth-child(2) {
        flex: 5;
      }

      > div:nth-child(3) {
        text-align: right;
        flex: 3;
      }

      .article-title {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #333;
      }
    }
  }

  .update-container {
    padding: 1.5rem;
    margin-top: 1em;
    text-align: center;
    border-radius: $border-radius;
  }

}
</style>
