<template>
  <div class="edit-blogbook">
    <loading v-if="loading"/>
    <template v-else>
      <b-card class="select-bar" v-headroom>
        <b-row align-v="center" v-if="!selecting">
          <b-col>
            <b>编辑文章《{{article.title | truncate(7)}}》</b>
          </b-col>
          <b-col cols="auto">
            <b-link @click="sorting = !sorting; cutting = null" :disabled="paragraphs.length <= 1">
              <fa :icon="sorting ? 'check' : 'list'"/>
              {{sorting ? '完成' : '排序'}}
            </b-link>
            <b-link @click="selecting = !selecting" class="ml-2" v-if="!sorting"
                    :disabled="!paragraphs.length">
              <fa icon="list-alt"/>
              批量删除
            </b-link>
          </b-col>
        </b-row>

        <b-row align-v="center" v-else>
          <b-col cols="auto">
            <b style="cursor: pointer; user-select: none;" :class="{'text-primary': allChecked}"
               @click="allChecked = !allChecked">
              <fa icon="check-circle" v-if="allChecked"/>
              <fa icon="circle" far class="text-muted" v-else/>
              全选
            </b>
          </b-col>
          <b-col>
            已选
            <span :class="{'text-primary': selected.length}">{{selected.length}}</span>
            条
          </b-col>
          <b-col cols="auto">
            <b-link class="mr-2" :disabled="saving || !selected.length" @click="deleteSelected">
              <fa icon="spinner" spin v-if="saving"/>
              <fa icon="trash" v-else/>
              删除已选
            </b-link>
            <b-link :disabled="saving" @click="cancelSelect">取消</b-link>
          </b-col>
        </b-row>
      </b-card>

      <div class="side-menu float-right" v-if="!isSmallScreen">
        <side-menu :items="navs" v-headroom="'1rem'"/>
      </div>

      <b-card>
        <template slot="header">
          <b>文章信息</b>
          <div class="float-right">
            <template v-if="!editing">
              <b-link class="ml-2" @click="editing = !editing">
                <fa icon="pencil"/>
                编辑
              </b-link>
            </template>
            <template v-else>
              <b-link class="text-body" @click="cancelEdit">取消</b-link>
              <b-link class="ml-2" @click="saveArticleStyle" :disabled="saving">
                <fa icon="loading" v-if="saving"/>
                <fa icon="check" v-else/>
                保存
              </b-link>
            </template>
          </div>
        </template>

        <template v-if="editing">
          <b-form-group label="标题内容">
            <b-input trim placeholder="请输入文章标题" size="lg" v-model="article.title"/>
          </b-form-group>
          <b-form-group label="发布时间" v-if="canEditTime">
            <b-input type="date" class="form-control" min="1970-01-01" max="2099-12-31" v-model="article.postDate"/>
          </b-form-group>
          <b-form-group label="正文对齐方式">
            <select-group required v-model="articleAlign" :options="alignOptions"/>
            <div slot="description">此项将会影响所有段落的对齐方式</div>
            <div class="mt-2">
              <b-check switch v-model="overwrite">强制覆盖所有段落</b-check>
            </div>
          </b-form-group>
          <b-form-group label="标题对齐方式">
            <select-group required v-model="titleAlign" :options="alignOptions"/>
          </b-form-group>
          <b-form-group label="日期对齐方式" v-if="dateEnabled">
            <select-group required v-model="dateAlign" :options="alignOptions"/>
          </b-form-group>
          <b-form-group class="mb-0" label="文章删除后将放入回收站">
            <b-btn variant="danger" @click="deleteArticle">删除文章</b-btn>
          </b-form-group>
        </template>

        <div :class="['text-' + titleAlign]" v-else>
          <h2>{{article.title}}</h2>
          <div class="text-muted">
            <div>
              发布于
              <datetime :value="article.postDate" format="YYYY.MM.DD"/>
              /
              共 {{paragraphs.length}} 段落
            </div>
            <div v-if="book.title">收录于《{{book.title}}》</div>
          </div>
        </div>
      </b-card>

      <loading v-if="polling" text="内容更新中，请稍候..."/>
      <template v-else>
        <div class="paragraph-items" v-if="paragraphs && paragraphs.length">
          <div class="paragraph"
               :class="{selecting, sorting}"
               v-for="(item, i) in currentParagraphs"
               :key="item.id"
               :data-id="item.id">
            <div class="prepend text-center mb-3">
              <template v-if="!sorting && !selecting">
                <b-btn size="sm" @click="addParagraph(item.id)" :disabled="savingParagraph">
                  <fa icon="paragraph" fw/>
                  添加段落
                </b-btn>
              </template>
            </div>
            <paragraph-item :disabled="!item.id || sorting || selecting"
                            :bid="bid"
                            :aid="aid"
                            :index="i"
                            :class="{new: added === item.id}"
                            v-model="currentParagraphs[i]"
                            :selected="selected.includes(item) || cutting === item"
                            @click.native="toggleSelect(item)"
                            @save="onSave"
                            @delete="onDelete">
              <template slot="header">
                <template class="text-muted" v-if="selecting">
                  <b v-if="selected.includes(item)">
                    <fa icon="check"/>
                    已选中
                  </b>
                  <span class="text-muted" v-else>点击任意位置选中</span>
                </template>
                <template v-else-if="sorting">
                  <b v-if="cutting === item">
                    <fa icon="check"/>
                    已剪切
                  </b>
                  <template v-else-if="cutting">
                    <span class="text-muted">移到此段</span>
                    <b-link @click.stop="sortParagraph(i)" v-if="i === 0" :disabled="saving">
                      <fa icon="caret-up"/>
                      之前
                    </b-link>
                    <b-link @click.stop="sortParagraph(i, true)"
                            :disabled="i + 1 === currentParagraphs.indexOf(cutting) || saving">
                      之后
                      <fa icon="caret-down"/>
                    </b-link>
                  </template>
                  <span class="move" v-else>
                    <fa icon="cut"/>
                    点击任意位置剪切
                  </span>
                </template>
              </template>
            </paragraph-item>
          </div>
        </div>

        <empty v-if="!paragraphs.length" icon="paragraph">本文还没有段落哦，点击下面按钮添加</empty>

        <div class="text-center" v-if="!sorting && !selecting">
          <b-btn size="sm" @click="addParagraph(null)" :disabled="savingParagraph">
            <fa icon="paragraph" fw/>
            添加段落
          </b-btn>
        </div>

        <footer class="mt-3" v-if="pagination">
          <b-pagination align="center" v-model="page" v-bind="pagination"/>
        </footer>
      </template>

      <write-paragraph ref="writer" :bid="bid" :aid="aid" :pid="prepending" @save="onAdd"/>

      <bottom-bar :items="navs" nav v-if="isSmallScreen"/>
    </template>
  </div>
</template>

<script>
import { chain } from 'lodash'
import ParagraphItem from '@/components/ParagraphItem'
import { decodeBookParams } from '@/models/book'
import routeData from '@/mixins/route-data'
import bookUtils from '@/mixins/book-utils'
import WriteParagraph from '@/components/WriteParagraph'
import SideMenu from '@/components/SideMenu'

export default {
  name: 'editBlogbook',
  title: '编辑内容',
  mixins: [
    routeData('book', {relativeParam: 'bookId'}),
    routeData('article'),
    bookUtils
  ],
  components: {
    SideMenu,
    ParagraphItem,
    WriteParagraph
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.$el.querySelector('.paragraph-item.editing')) {
      return next()
    }
    const confirmed = await this.$dialog.confirm({
      title: '未保存的内容',
      content: '您有未保存的内容，是否忽略未保存的内容并离开',
      okTitle: '忽略并离开',
      cancelTitle: '留下'
    })
    if (!confirmed) {
      return
    }
    next()
  },
  data() {
    return {
      page: 1,
      pageSize: 50,

      loaded: false,
      saving: false,
      saved: false,
      editing: false,
      sorting: false,
      selecting: false,
      polling: false,

      cutting: null,
      selected: [],
      original: {},

      titleAlign: '',
      dateAlign: '',
      articleAlign: '',
      overwrite: false,

      bid: '',
      aid: '',
      added: null,
      prepending: null,
      paragraphs: [],
      addType: '',

      article: {
        title: '暂无标题',
        postDate: new Date().toJSON()
      },
      alignOptions: [
        {
          value: '',
          title: '默认'
        },
        {
          value: 'left',
          icon: 'align-left',
          title: '左对齐'
        },
        {
          value: 'center',
          icon: 'align-center',
          title: '居中对齐'
        },
        {
          value: 'right',
          icon: 'align-right',
          title: '右对齐'
        }
      ],

      interval: null
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    const {bookId, aid} = this.$route.params
    const {id} = decodeBookParams(bookId)
    this.bid = id
    this.aid = aid
  },
  computed: {
    canEditTime() {
      // return this.article.sourceSite === 'xinshu'
      return true
    },
    navs() {
      return [
        {
          title: '预览文章',
          icon: 'book',
          to: '../months/' + this.$route.params.aid,
          disabled: this.saved || this.saving
        },
        {
          title: this.saved ? '已保存' : '返回目录',
          icon: this.saved ? 'check' : 'chevron-left',
          to: '../catalog',
          variant: 'primary',
          disabled: this.saved || this.saving
        }
      ]
    },
    allChecked: {
      get() {
        return this.selected.length === this.paragraphs.length
      },
      set(val) {
        if (val) {
          this.selected = []
          this.selected.push(...this.paragraphs)
        } else {
          this.selected = []
        }
      }
    },
    currentParagraphs() {
      const start = this.pageSize * (this.page - 1)
      return this.paragraphs.slice(start, start + this.pageSize)
    },
    dateEnabled() {
      return /displayed/.test(this.book.style?.dateStyle)
    },
    pagination() {
      if (this.pageSize > this.paragraphs.length) {
        return null
      }
      return {
        perPage: this.pageSize,
        totalRows: this.paragraphs.length
      }
    },
    paragraphIds() {
      return this.paragraphs.map(i => i.id)
    },
    elements() {
      return chain(this.paragraphs).map(i => i.elements).flatten().value()
    },
    savingParagraph() {
      return this.$refs.writer?.saving
    }
  },
  methods: {
    async onLoad() {
      this.titleAlign = this.article.titleStyle
      this.articleAlign = this.article.style
      this.dateAlign = this.article.dateStyle
      this.article.postDate = this.$day(this.article.postDate).format('YYYY-MM-DD')

      this.original.title = this.article.title
      this.original.postDate = this.article.postDate
      this.original.articleAlign = this.articleAlign
      this.original.titleAlign = this.titleAlign
      this.original.dateAlgin = this.dateAlign

      if (this.article?.sourceInfo?.crawlFinished === false) {
        await this.pollingArticle()
      }

      this.paragraphs = this.article?.content?.paragraphs || []

      if (this.$route.query.msgId) {
        const index = this.paragraphIds.indexOf(this.$route.query.msgId)
        this.page = Math.ceil((index + 1) / this.pageSize)
      }
    },
    pollingArticle() {
      return new Promise((resolve, reject) => {
        clearInterval(this.interval)
        let i = 0
        this.polling = true
        this.interval = setInterval(async () => {
          if (i > 100) {
            return reject(new Error('Polling timeout'))
          }
          const result = await this.$ajax.fetchArticle({aid: this.aid})
          if (result.sourceInfo?.crawlFinished) {
            this.article = result
            this.article.postDate = this.$day(this.article.postDate).format('YYYY-MM-DD')
            clearInterval(this.interval)
            this.polling = false
            resolve()
            return
          }
          i++
        }, 2000)
      })
    },
    async deleteArticle() {
      const confirmed = await this.$dialog.confirm('是否要删除当前文章，删除后可从回收站恢复')
      if (!confirmed) {
        return
      }
      await this.$ajax.deleteBlogbookArticle({bid: this.bid, aids: [this.aid]})
      this.$alert.success('文章已删除')
      this.$router.back()
    },
    addParagraph(pid) {
      if (pid === null && this.currentParagraphs.length) {
        pid = '+' + this.currentParagraphs[this.currentParagraphs.length - 1].id
      }
      this.prepending = pid
      this.$bvModal.show('addParagraph')
    },
    async saveArticleStyle() {
      try {
        this.saving = true
        const data = {
          title: this.article.title,
          titleStyle: this.titleAlign,
          style: this.articleAlign
        }
        if (this.overwrite) {
          data.style += ';overwrite'
        }
        if (this.dateEnabled) {
          data.dateStyle = this.dateAlign
        }
        if (this.canEditTime) {
          data.postDate = this.article.postDate
        }
        const result = await this.$req.post(
          `/blogbook/books/${this.bid}/articles/${this.aid}/`,
          data
        )
        this.$alert.success('文章信息修改成功')
        this.onSave()

        this.title = result.title
        this.titleAlign = result.titleStyle
        this.articleAlign = result.style
        this.overwrite = false
        this.article.postDate = this.$day(result.postDate).format('YYYY-MM-DD')

        this.original.title = result.title
        this.original.postDate = this.$day(result.postDate).format('YYYY-MM-DD')
        this.original.articleAlign = result.style
        this.original.titleAlign = result.titleStyle
        this.original.dateAlgin = result.dateStyle

        this.editing = false
      } finally {
        this.saving = false
      }
    },
    cancelEdit() {
      this.article.title = this.original.title
      this.article.postDate = this.original.postDate
      this.article.titleAlign = this.original.titleAlign
      this.article.articleAlign = this.original.articleAlign
      this.article.dateAlign = this.original.dateAlign
      this.editing = false
    },
    onAdd(item, pid) {
      let index = -1
      const isAppend = /^\+/.test(pid)
      if (pid) {
        if (isAppend) {
          // 加到目标段落之后
          index = this.paragraphIds.indexOf(pid.slice(1)) + 1
        } else {
          // 加到目标段落之前
          index = this.paragraphIds.indexOf(pid)
        }
      }
      item.added = true
      if (index > -1) {
        this.paragraphs.splice(index, 0, item)
        if (isAppend && this.page < Math.ceil(this.paragraphs.length / this.pageSize)) {
          this.page++
        }
      } else {
        // pid 为 null 的情况
        this.paragraphs.push(item)
      }
      this.added = item.id
      this.$alert.success('段落已添加')
      setTimeout(() => {
        this.scrollIntoView('[data-id="' + item.id + '"]')
      })
    },
    onDelete(item) {
      this.paragraphs.splice(this.paragraphs.indexOf(item), 1)
      this.$alert.success('段落已删除')
    },
    onSave() {
      this.saved = true
      setTimeout(() => {
        this.saved = false
      }, 1000)
    },
    async sortParagraph(index, after) {
      try {
        this.saving = true
        const oldItem = this.cutting
        const newItem = this.currentParagraphs[index]
        await this.$req.post(`/blogbook/books/${this.bid}/articles/${this.aid}/`, {
          paragraphOrder: {
            move: oldItem.id,
            [after ? 'after' : 'before']: newItem.id
          }
        })
        const oldIndex = this.paragraphs.indexOf(oldItem)
        let newIndex = this.paragraphs.indexOf(newItem)
        if (newIndex < oldIndex && after) {
          newIndex++
        }
        this.paragraphs.splice(newIndex, 0, this.paragraphs.splice(oldIndex, 1)[0])
        this.$alert.success('排序成功')
        this.onSave()
      } finally {
        this.saving = false
      }
    },

    toggleSelect(item) {
      if (this.selecting) {
        if (this.selected.includes(item)) {
          this.selected = this.selected.filter(i => i !== item)
        } else {
          this.selected = this.selected.concat(item)
        }
      }
      if (this.sorting) {
        if (this.cutting === item) {
          this.cutting = null
        } else {
          this.cutting = item
        }
      }
    },
    cancelSelect() {
      this.selected = []
      this.selecting = false
    },
    async deleteSelected() {
      try {
        if (!this.selected.length) {
          return this.$alert.error('不能删除空内容哦')
        }
        const confirmed = await this.$dialog.confirm({
          title: '删除内容',
          content: `是否批量删除已选的 ${this.selected.length} 条内容`
        })

        if (!confirmed) {
          return
        }

        this.saving = true

        await this.$req.post(`/blogbook/books/${this.bid}/articles/${this.aid}/`, {
          deleteParagraphs: this.selected.map(item => item.id)
        })

        this.$alert.success('内容删除成功')

        this.paragraphs = this.paragraphs.filter(p => !this.selected.includes(p))
        this.selected = []
        this.onSave()
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-blogbook {
  max-width: 640px;
  position: relative;

  .paragraph {
    &.selecting, &.sorting {
      cursor: pointer;
    }
  }

  .paragraph-item {
    &.new {
      border-color: $success;
    }
  }

  .headroom ~ .card {
    margin-top: 72px;
  }

  .select-bar {
    @include clearfix();
    margin-bottom: 1em;

    &.headroom {
      z-index: 20;
      top: 0;
      border-top: 0;
      width: 100%;
      max-width: 620px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      ~ .title-box {
        margin-top: 72px;
      }
    }
  }
}
</style>
