<template>
  <transition name="fade">
    <b-modal class="write-article" :ok-disabled="!canSave"
             :title="'新建文章至《' + book.title + '》'"
             @cancel="close" @ok="submit" cancel-title="取消"
             ok-title="确定" v-model="dialogActive">
      <div class="addArticle-box">
        <cell group="addArticle" reverse title="原创一篇" v-model="source" val="original">
          <template slot="body">
            <div class="coupon-name">
              <b>原创一篇</b>
            </div>
            <div class="small text-muted">
              自己撰写全部内容
            </div>
          </template>
        </cell>
        <cell group="addArticle" reverse title="导入文章" v-model="source" val="import">
          <template slot="body">
            <div class="coupon-name">
              <b>导入文章</b>
            </div>
            <div class="small text-muted">
              可导入公众号、博客、美篇等平台文章
            </div>
          </template>
        </cell>
        <cell :disabled="isMobile" group="addArticle" reverse
              title="导入Word" v-model="source" val="word">
          <template slot="body">
            <div class="coupon-name">
              <b>导入Word</b>
            </div>
            <div class="small text-muted">自动解析Word文档，需在电脑端操作</div>
          </template>
        </cell>
        <cell group="addArticle" reverse title="导入历史来源" v-model="source" val="history"
              v-if="!isPartnerUser">
          <template slot="body">
            <div class="coupon-name">
              <b>导入历史来源</b>
            </div>
            <div class="small text-muted">
              可导入曾经导入过的文章
            </div>
          </template>
        </cell>
      </div>
      <hr class="dashed">
      <template v-if="step === 1">
        <b-form-group label="文章标题" v-if="source === 'original'">
          <b-input size="lg" trim placeholder="输入文章标题（最长50字）" autofocus
                   v-model="newTitle" maxlength="50" @keyup.enter="submit"/>
        </b-form-group>

        <b-form-group label="文章链接" v-if="source === 'import'">
          <b-input size="lg" trim autofocus
                   placeholder="粘贴文章链接"
                   type="url" maxlength="1024"
                   v-model="url"/>
        </b-form-group>
      </template>

      <template v-else-if="step === 2">
        <loading v-if="searching" text="搜索中..."/>
        <template v-else>
          <b-form-group label="链接">
            <b-input :value="newTitle" readonly/>
          </b-form-group>
          <b-form-group label="标题">
            <b-input :value="article.title" readonly/>
          </b-form-group>
          <b-form-group label="作者">
            <b-input :value="article.author" readonly/>
          </b-form-group>
          <b-form-group label="更新时间">
            <b-input :value="postDate" readonly/>
          </b-form-group>
        </template>
      </template>
    </b-modal>
  </transition>
</template>

<script>
import routeData from '@/mixins/route-data'
import { addBlogbookArticle, searchArticle } from '@/models/blogbook'

export default {
  name: 'writeArticle',
  mixins: [routeData('book')],
  computed: {
    canSave() {
      if (this.source === 'original' && !this.newTitle) {
        return false
      }
      if (this.source === 'import' && !this.url) {
        return false
      }
      return !this.saving
    },
    dialogActive: {
      get() {
        return !!this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    postDate() {
      return this.$day(this.article.postDate).utcOffset(480).format('YYYY/MM/DD HH:mm')
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    source(val) {
      this.init()
      if (val === 'word') {
        this.gotoWord()
      }
      if (val === 'history') {
        this.gotoHistory()
      }
    }
  },
  data() {
    return {
      saving: false,
      newTitle: '',
      url: '',
      source: 'original',
      step: 1,
      searching: false,
      article: {}
    }
  },
  methods: {
    init() {
      this.step = 1
      this.article = {}
      this.searching = false
    },
    async submit(event) {
      if (this.step === 1) {
        event && event.preventDefault()
        if (!this.canSave) {
          return this.$alert.warn('不能添加空标题哦')
        }
        if (this.source === 'original') {
          const req = {
            bid: this.book.id,
            title: this.newTitle
          }
          this.save(req)
        } else if (this.source === 'import') {
          if (!/^https?:\/\//.test(this.url)) {
            this.$alert.error('链接格式错误，请检查后再试')
            return
          }
          this.step = 2
          this.searching = true
          try {
            this.article = await searchArticle({url: this.url})
          } catch (error) {
            this.step = 1
          } finally {
            this.searching = false
          }
        }
      } else {
        const req = {
          bid: this.book.id,
          url: this.url,
          title: this.article.title,
          author: this.article.author,
          source_site: this.article.sourceSite,
          post_date: this.article.postDate || ''
        }
        this.save(req)
      }
    },
    async save(data) {
      this.saving = true

      try {
        const result = await addBlogbookArticle(data)
        this.$alert.success('新建文章成功')

        this.$emit('save', result)
        this.close()
        const bookId = this.$route.params.bookId
        this.$router.push(`/books/${bookId}/editblogbook/${result.id}`)
      } finally {
        this.saving = false
      }
    },
    close() {
      this.dialogActive = false
      this.step = 1
    },
    gotoWord() {
      const bookId = this.$route.params.bookId
      this.$router.push(`/books/${bookId}/word`)
    },
    gotoHistory() {
      const bookId = this.$route.params.bookId
      this.$router.push(`/books/${bookId}/creation`)
    }
  }
}
</script>

<style scoped lang="scss">
.write-article {
  textarea {
    min-height: 6em;
    max-height: calc(100vh - #{360px});
  }
}

.addArticle-box {
  margin: 0 0.2rem 0 0.2rem;
}
</style>
